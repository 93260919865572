import dayjs from "dayjs";

/**
 * Utility function for date format..
 * Format date without time
 * The output format will be 18-09-2023 or 18/09/2023
 * */
export function formatDate({
  date,
  separator = "-",
}: {
  date: string | number | Date;
  separator?: string;
}) {
  return dayjs(date).format(`DD${separator}MM${separator}YYYY`);
}

/**
 * Utility function for date format..
 * Format date without time
 * The output format will be 18-09-2023 or 18/09/2023
 * */
export function formatDateTime({
  date,
}: {
  date: string | number | Date;
  separator?: string;
}) {
  return dayjs(date).format(`MMM DD, YYYY - HH:mm`);
}
