import { Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";

export default function TextWithLineBreak({
  text,
}: {
  text: string | undefined;
}) {
  const [displayedText, setDisplayedText] = useState("");
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (!text) return;

    const interval = setInterval(() => {
      setDisplayedText((prev) => prev + text[index]);
      setIndex((prev) => prev + 1);
    }, 5);

    if (index === text.length) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [text, index]);

  const renderTextWithLinksAndFormatting = (text: string) => {
    const linkRegex = /(https?:\/\/[^\s]+)/g;
    const boldRegex = /\*\*(.*?)\*\*/g;
    const parts = text.split(linkRegex);

    return parts.map((part, i) => {
      if (linkRegex.test(part)) {
        return (
          <a key={i} href={part} target="_blank" rel="noopener noreferrer">
            {part}
          </a>
        );
      } else {
        const boldParts = part.split(boldRegex);
        return boldParts.map((boldPart, j) =>
          boldRegex.test(`**${boldPart}**`) ? (
            <strong key={j}>{boldPart}</strong>
          ) : (
            <Fragment key={j}>{boldPart}</Fragment>
          )
        );
      }
    });
  };

  const renderBulletPoints = (text: string) => {
    const lines = text.split("\n");
    return lines.map((line, i) => {
      if (line.startsWith("- ")) {
        return (
          <li key={i}>
            {renderTextWithLinksAndFormatting(line.substring(2))}
          </li>
        );
      } else {
        return (
          <Fragment key={i}>
            {renderTextWithLinksAndFormatting(line)}
            <br />
          </Fragment>
        );
      }
    });
  };

  return (
    <Typography paddingRight={"10px"}>
      <ul>{renderBulletPoints(displayedText)}</ul>
    </Typography>
  );
}
