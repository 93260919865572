import { Avatar, Box, Typography } from "@mui/material";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { ReactionButton } from "../ChatbotReactionButton";
import FeedbackButton from "../ChatbotFeedback";
import { IChart } from "@/models/threadMessage";
import { APP_ENV } from "@/config/env.config";
import { formatDateTime } from "@/utils/date.util";
import TypingMarkdown from "@/components/atoms/TypingMarkdown";
import { useState } from "react";
import ImageViewer from "react-simple-image-viewer";
import BouncingLoader from "@/components/atoms/BouncingLoader";

interface IMessageLeft {
  onSubmitChatbotFeedback?: ({
    request_id,
    feedback,
  }: {
    request_id?: string;
    feedback: string;
  }) => Promise<void>;
  feedback?: string;
  request_id?: string;
  message?: string;
  sql?: string | null;
  isLoading?: boolean;
  isLoadingFeedback?: boolean;
  like?: boolean | null;
  timestamp?: string;
  onThumbsUp?: () => void;
  onThumbsDown?: () => void;
  chart?: IChart;
  totalResponseTime?: number;
  showAvatar?: boolean;
  showFeedback?: boolean;
  showReaction?: boolean;
  showCode?: boolean;
  image?: string;
}

export const MessageLeft = ({
  message = "no message",
  feedback,
  sql = "",
  isLoading = false,
  isLoadingFeedback = false,
  onSubmitChatbotFeedback,
  like,
  request_id,
  onThumbsUp,
  onThumbsDown,
  chart,
  totalResponseTime,
  timestamp,
  showAvatar = true,
  showFeedback = true,
  showReaction = true,
  showCode = true,
  image,
}: IMessageLeft) => {
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState<string | null>(null);

  const openImageViewer = (src: string) => {
    setCurrentImage(src);
    setIsViewerOpen(true);
  };

  const closeImageViewer = () => {
    setCurrentImage(null);
    setIsViewerOpen(false);
  };

  const renderResponseTime = (responeTime: number) => {
    const timeInSec = parseInt(responeTime.toString()).toFixed(0);
    return `Thought for ${timeInSec} second${
      parseInt(timeInSec) > 1 ? "s" : ""
    }`;
  };
  return (
    <>
      <div
        style={{
          display: "flex",
        }}
      >
        {showAvatar && (
          <Avatar>
            <AutoAwesomeIcon />
          </Avatar>
        )}
        <div>
          {isLoading ? (
            <Box
              sx={{
                position: "relative",
                marginLeft: showAvatar ? "20px" : "0px",
                marginBottom: "20px",
                padding: "16px",
                paddingX: "16px",
                backgroundColor: "grey.200",
                width: "100%",
                textAlign: "left",
                borderRadius: "10px",
              }}
            >
              <Box width={"100%"} maxWidth={750}>
                <BouncingLoader />
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                position: "relative",
                marginLeft: showAvatar ? "20px" : "0px",
                marginBottom: "20px",
                padding: "12px",
                paddingX: "16px",
                backgroundColor: "secondary.light",
                // minWidth: "350px",
                maxWidth: "750px",
                // width: "60%",
                textAlign: "left",
                borderRadius: "10px",
              }}
            >
              {totalResponseTime && totalResponseTime > 0 ? (
                <Box mb={1}>
                  <Typography
                    variant="subtitle2"
                    fontWeight={500}
                    color={"primary"}
                    sx={{ opacity: 0.6 }}
                  >
                    {renderResponseTime(totalResponseTime)}
                  </Typography>
                </Box>
              ) : (
                <></>
              )}
              {/* <TextWithLineBreak text={message} /> */}

              <TypingMarkdown message={message} />

              {showCode && APP_ENV === "testing" && (
                <code>
                  <Typography
                    variant="h6"
                    color={"grey.700"}
                    sx={{
                      backgroundColor: "secondary.light",
                      borderRadius: "8px",
                      paddingX: "8px",
                      paddingY: "5px",
                      marginTop: "8px",
                      fontFamily: "monospace",
                    }}
                  >
                    {sql}
                  </Typography>
                </code>
              )}

              {image && (
                <img
                  src={image}
                  alt="image"
                  style={{
                    width: "100%",
                    marginTop: "10px",
                    borderRadius: "10px",
                    cursor: "pointer",
                  }}
                  onClick={() => openImageViewer(image)}
                />
              )}

              {isViewerOpen && currentImage && (
                <ImageViewer
                  src={[currentImage]}
                  currentIndex={0}
                  onClose={closeImageViewer}
                  backgroundStyle={{
                    backgroundColor: "rgba(0,0,0,0.9)",
                    zIndex: 1200,
                  }}
                />
              )}

              {chart && chart?.link && (
                <img
                  src={chart.link}
                  alt="chart"
                  style={{
                    width: "100%",
                    marginTop: "10px",
                    borderRadius: "10px",
                  }}
                />
              )}
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                marginTop="10px"
              >
                {showFeedback && APP_ENV === "testing" && (
                  <FeedbackButton
                    feedbackText={feedback}
                    isLoading={isLoadingFeedback}
                    onSubmit={onSubmitChatbotFeedback}
                    sql={sql}
                    message={message}
                    request_id={request_id}
                  />
                )}
                {showReaction && (
                  <div>
                    <ReactionButton
                      onClick={onThumbsUp}
                      reaction="thumbsUp"
                      selected={like === true}
                    />
                    <ReactionButton
                      onClick={onThumbsDown}
                      reaction="thumbsDown"
                      selected={like === false}
                    />
                  </div>
                )}
              </Box>

              {timestamp && (
                <Box
                  sx={{
                    textAlign: "right",
                  }}
                >
                  <Typography variant="subtitle2" color="primary">
                    {formatDateTime({ date: timestamp })}
                  </Typography>
                </Box>
              )}
            </Box>
          )}
        </div>
      </div>
    </>
  );
};

interface IMessageRight {
  message: string;
  timestamp?: string;
}

export const MessageRight = ({
  message = "no message",
  timestamp,
}: IMessageRight) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
      }}
    >
      <Box
        sx={{
          position: "relative",
          marginRight: "20px",
          marginBottom: "20px",
          padding: "12px",
          paddingX: "16px",
          backgroundColor: "primary.dark",
          width: "60%",
          textAlign: "left",
          borderRadius: "10px",
        }}
      >
        <Typography color="white">{message}</Typography>
        {timestamp && (
          <Box
            sx={{
              textAlign: "right",
            }}
          >
            <Typography variant="subtitle2" color="white">
              {formatDateTime({ date: timestamp })}
            </Typography>
          </Box>
        )}
      </Box>
    </div>
  );
};
