import useNotification from "@/hooks/useNotification";
import { chatbotApi } from "@/store/api/chatbot";
import { MESSAGES } from "@/config/dialogMessages.config";
import { AxiosError } from "axios";
import { handleInternalServerError } from "@/utils/errorHandler";
import { IThreadMessage } from "@/models/threadMessage";
import { IConversation } from "@/models/conversation";

export function sortByCreatedAt(data: IConversation[] = []): IConversation[] {
  return [...data].sort((a: IConversation, b: IConversation) => {
    return new Date(a?.CreatedAt).getTime() - new Date(b?.CreatedAt).getTime();
  }) as IConversation[];
}

/**
 * Chatbot utility hook
 */
export default function useChatbotConversation() {
  const { addNotification } = useNotification();

  const [getConversation, { isLoading, isFetching }] =
    chatbotApi.endpoints.conversation.useLazyQuery();

  const fetchConversation = async ({
    session_id,
  }: {
    session_id: string;
  }): Promise<IThreadMessage[] | false> => {
    try {
      const conversation = await getConversation({
        session_id,
      });
      console.log("converastion is ", conversation);

      if (!conversation.data) return false;

      let threadMessages: IThreadMessage[] = [];
      sortByCreatedAt(conversation.data.data).forEach((conversationMessage) => {
        threadMessages = [
          ...threadMessages,
          {
            orientation: "right",
            message: conversationMessage.UserQuery,
            request_id: conversationMessage.RequestID,
            sql: "",
          },
          {
            orientation: "left",
            message: conversationMessage.AIResponse,
            request_id: conversationMessage.RequestID,
            sql: conversationMessage.sql,
            chart: {
              type: "bar",
              link: conversationMessage.link,
            },
            feedback: conversationMessage?.Feedbacks?.[0]?.FeedbackText || "",
            like: conversationMessage?.Feedbacks?.[0]?.FeedbackType || null,
          },
        ];
      });
      return threadMessages;
    } catch (err) {
      console.log("error is ", err);
      const error = err as AxiosError;

      if (error?.response?.status === 500) {
        handleInternalServerError({ addNotification, error });
      } else {
        addNotification({
          title: "Oops!",
          message: MESSAGES.GENERAL_ERROR_MESSAGE,
          type: "error",
        });
      }

      //   onFailure?.();
      //   sentryCaptureException({
      //     tag: {
      //       name: SENTRY_TAG_NAME.API_NAME,
      //       value: "Chatbot Query",
      //     },
      //     error: err,
      //   });

      return false;
    }
  };

  return {
    fetchConversation,
    isLoading: isLoading || isFetching,
  };
}
