import * as React from "react";
import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import FeedbackOutlined from "@mui/icons-material/FeedbackOutlined";
import LoadingButton from "@mui/lab/LoadingButton";
import TextWithLineBreak from "@/components/molecules/TextWithLineBreak";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  minWidth: 400,
  maxHeight: "calc(100vh - 60px)",
  overflow: "auto",
  transform: "translate(-50%, -50%)",
  maxWidth: 600,
  bgcolor: "background.paper",
  border: 3,
  borderColor: "primary.main",
  borderRadius: "20px",
  boxShadow: 24,
  px: 3,
  py: 4,
};

export interface IFeedbackButton {
  request_id?: string;
  message?: string;
  sql?: string | null;
  isLoading?: boolean;
  onSubmit?: ({
    request_id,
    feedback,
  }: {
    request_id?: string;
    feedback: string;
  }) => Promise<void>;
  feedbackText?: string;
}

const FeedbackButton = ({
  feedbackText,
  message,
  sql,
  isLoading,
  onSubmit,
  request_id,
}: IFeedbackButton) => {
  const [open, setOpen] = React.useState(false);
  const [feedback, setFeedback] = React.useState("");

  React.useEffect(() => {
    if (feedbackText) {
      setFeedback(feedbackText);
    }
  }, [feedbackText]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    // Submit feedback logic here
    console.log("Feedback submitted:", feedback);
    // handleClose();
    if (request_id) {
      onSubmit &&
        onSubmit({ request_id, feedback: feedback }).then(() => {
          handleClose();
        });
    }
  };

  const handleCancel = () => {
    handleClose();
  };

  return (
    <div>
      <Button
        size="small"
        variant={"text"}
        endIcon={feedbackText ? false : <FeedbackOutlined />}
        onClick={handleOpen}
      >
        Feedback{" "}
        {feedbackText && (
          <Box
            sx={{
              backgroundColor: "primary.main",
              color: "white",
              marginLeft: 0.5,
              paddingX: 1,
              borderRadius: 6,
            }}
          >
            Submitted
          </Box>
        )}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="feedback-modal-title"
        aria-describedby="feedback-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              position: "relative",
              marginBottom: "20px",
              padding: "10px",
              backgroundColor: "secondary.light",
              textAlign: "left",
              borderRadius: "10px",
            }}
          >
            <TextWithLineBreak text={message} />
            <code>
              <Typography
                variant="h6"
                color={"grey.700"}
                sx={{
                  backgroundColor: "secondary.light",
                  borderRadius: "8px",
                  paddingX: "8px",
                  paddingY: "5px",
                  marginTop: "8px",
                  fontFamily: "monospace",
                }}
              >
                {sql}
              </Typography>
            </code>
          </Box>
          <Typography id="feedback-modal-title" variant="h3" component="h2">
            Leave your feedback
          </Typography>
          <Box display="flex" flexDirection="column">
            <TextField
              id="feedback-comment"
              label="Comment"
              multiline
              rows={4}
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
              sx={{ mt: 2, mb: 2 }}
            />

            <Box
              data-cy="invite-team-members-action"
              sx={{
                pt: 3,
                gap: 1,
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button onClick={handleCancel}>Cancel</Button>
              <LoadingButton
                loading={isLoading}
                loadingPosition="center"
                onClick={handleSubmit}
                variant="contained"
                disabled={isLoading || !feedback}
              >
                {feedbackText ? "Update Feedback" : "Submit Feedback"}
              </LoadingButton>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default FeedbackButton;
