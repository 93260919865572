import { ChatBotTemplate } from "@/components/templates/ChatBotTemplate";
import { IConversationEvents } from "@/models/conversation";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useSearchParams } from "react-router-dom";

export default function InfoAgentDataBot() {
  const { data_model } = useParams();

  const [searchParam] = useSearchParams();

  const [events, setEvents] = useState<IConversationEvents>({});

  useEffect(() => {
    console.log("effect");
    const handleMessage = (event: MessageEvent<IConversationEvents>) => {
      console.log("getting event message =======> ", event.data);
      //event.origin === 'http://localhost:3000'
      // if (event.data.ip) {
      //   setIp(event.data.ip);
      // }
      console.log("event.data ==> ", event.data);
      if (event?.data?.fbclid || event?.data?.ip) {
        console.log("persisting event.data");
        setEvents(event.data);
      }
    };

    window.addEventListener("message", handleMessage);
    return () => window.removeEventListener("message", handleMessage);
  }, []);

  if (!data_model) return <div>No Data Model Found</div>;
  return (
    <ChatBotTemplate
      data_model={data_model}
      info_agent_webview={true}
      events={events}
      instant_chat={searchParam.get("instant_chat") == "true" || false}
    />
  );
}
