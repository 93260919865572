import useFetchAccountState from "@/hooks/store-management/useFetchAccountState";
import { ISession } from "@/models/session";
import { Add, Chat } from "@mui/icons-material";
import {
  Box,
  Button,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import { useNavigate, useParams } from "react-router";

type Props = {
  data?: ISession[];
};

export default function ChatbotSidebar({ data }: Props) {
  const navigate = useNavigate();

  const params = useParams();

  const { accountState } = useFetchAccountState({
    fetchOnlyStore: true,
  });

  const getPath = (sessionId: string) =>
    `/databot/${sessionId}?account=${accountState.name.toLowerCase()}`;

  const navigateToSession = ({ sessionId }: { sessionId: string }) => {
    if (getPath(sessionId) !== getPath(params?.sessionId || "")) {
      navigate(getPath(sessionId));
    }
  };

  const handleNavigateToNewChat = () => {
    navigate(`/databot?account=${accountState.name.toLowerCase()}`);
  };

  return (
    <Box>
      <Button
        startIcon={<Add />}
        onClick={handleNavigateToNewChat}
        variant="text"
        sx={{
          borderRadius: 20,
          backgroundColor: "",
          px: 2,
        }}
      >
        New Chat
      </Button>
      <List
        sx={{ width: "100%" }}
        subheader={
          <ListSubheader
            sx={{
              background: "transparent",
            }}
            component="div"
            id="nested-list-subheader"
          >
            Recent
          </ListSubheader>
        }
      >
        {data?.[0] ? (
          <>
            {data?.map((item) => (
              <ListItemButton
                key={item.SessionID}
                selected={params?.sessionId === item.SessionID}
                onClick={() => navigateToSession({ sessionId: item.SessionID })}
                sx={{
                  marginBottom: 0.5,
                  borderRadius: "10px",
                }}
              >
                <ListItemIcon sx={{ color: "inherit" }}>
                  <Chat />
                </ListItemIcon>

                <ListItemText
                  primary={item.initialQuery}
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    width: "100%",
                    "& span": {
                      display: "inline",
                    }
                  }}
                />
              </ListItemButton>
            ))}
          </>
        ) : (
          <>
            <ListItemText
              sx={{ pl: 2, color: "background.paper" }}
              primary={"No recent conversation."}
            />
          </>
        )}
      </List>
    </Box>
  );
}
