import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "./baseQuery";
import { CHATBOT_SALES_AGENT_API_BASE_URL } from "@/config/env.config";

const CHATBOT_API_TIMEOUT = 120000;

const baseChatbotSalesAgentAPI = createApi({
  reducerPath: "baseCahtbotSalesAgentApi",
  baseQuery: axiosBaseQuery({ baseUrl: CHATBOT_SALES_AGENT_API_BASE_URL || "", timeout: CHATBOT_API_TIMEOUT}),
  tagTypes: [
    "chatbot"
  ],
  endpoints: () => ({}),
});

export default baseChatbotSalesAgentAPI;