import { IGenericHookProps } from "@/shared/type";
import { MESSAGES } from "@/config/dialogMessages.config";
import useNotification from "@/hooks/useNotification";
import { useState } from "react";
import { handleInternalServerError } from "@/utils/errorHandler";
import { sentryCaptureException } from "@/services/sentry.service";
import { SENTRY_TAG_NAME } from "@/config/sentry.config";
import { AxiosError } from "axios";
import { useMessageReactionMutation } from "@/store/api/chatbot";
import { useAppSelector } from "@/store";

/**
 * Chatbot utility hook
 */
export default function useChatbotMessageReaction({
  onSuccess,
  onFailure,
}: IGenericHookProps) {
  const [isLoading, setIsLoading] = useState(false);
  const { addNotification } = useNotification();
  const [queryChatbotMessageReactionMutation] = useMessageReactionMutation();
  const selectedAccount = useAppSelector(
    (state) => state.store.selectedAccount
  );

  const queryChatbotMessageReaction = async ({
    like,
    request_id,
  }: {
    like: boolean;
    request_id: string;
  }) => {
    try {
      setIsLoading(true);

      const response = await queryChatbotMessageReactionMutation({
        like,
        request_id,
        store_id: selectedAccount,
      });
      console.log("response is ", response);
      if ("data" in response) {
        /**
         * res.data
         * The data should contain the object similar to mockMemberList..
         * Pending Invitation = if auth0Id is null then it will be
         * treated as pending invitation.
         */
        onSuccess?.();

        return response.data || {};
      } else {
        addNotification({
          title: "Oops!",
          message: MESSAGES.GENERAL_ERROR_MESSAGE,
          type: "error",
        });

        return false;
      }
    } catch (err) {
      console.log("error is ", err);
      const error = err as AxiosError;

      if (error?.response?.status === 500) {
        handleInternalServerError({ addNotification, error });
      } else {
        addNotification({
          title: "Oops!",
          message: MESSAGES.GENERAL_ERROR_MESSAGE,
          type: "error",
        });
      }

      onFailure?.();
      sentryCaptureException({
        tag: {
          name: SENTRY_TAG_NAME.API_NAME,
          value: "Chatbot Query",
        },
        error: err,
      });

      return false;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    queryChatbotMessageReaction,
    isLoading,
  };
}
