import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import type { GridCellParams, GridColDef, GridColumnVisibilityModel } from "@mui/x-data-grid-premium";
import ScoreChart from "@/components/molecules/Charts/ScoreCharts";

export interface IColDef {
    field: string;
    headerName: string;
    width: number;
    type: string;
    cellClassName: (params: GridCellParams<any, number>) => string;
    renderCell: (params: GridCellParams<any, number>) => React.ReactNode;
    minWidth: number;
    adCreativeImage?: string;
    groupable?: boolean;
}

export type ColumnsDefinition = GridColDef<IColDef> & { visible: boolean; };

export const adDashColumnsAllAttributes: ColumnsDefinition[] = [
    {
        field: "adName",
        visible: true,
        headerName: "Ad",
        width: 300,
        minWidth: 300,
        renderCell: (params) => {
            const addDefaultSrc = (ev: any) => {
                ev.target.src = "/no-image.png";
            };
            return (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <img
                        src={params.row.adCreativeImage}
                        alt={""}
                        width={"40px"}
                        height={"40px"}
                        onError={addDefaultSrc}
                    />
                    <Typography variant="inherit" sx={{ marginLeft: 2 }}>
                        {params.value}
                    </Typography>
                </Box>
            );
        },
    },
    {
        field: "adID",
        headerName: "Ad ID",
        width: 120,
        visible: false,
    },
    {
        field: "adScore",
        visible: true,
        headerName: "Ad Score",
        width: 140,
        renderCell: (params) => {
            return (
                <Typography variant="inherit">
                    {params.value === null ? "No score, threshold applied" : params.value}
                </Typography>
            );
        },
        cellClassName: (params: GridCellParams<any, number>) => {
            if (!params?.value) {
                return "";
            }

            return params.value >= 45 ? "max" : params.value >= 25 && params.value < 45 ? "mid" : "min";
        },
    },
    {
        field: "adScoreChangeInPercent",
        headerName: "% change",
        renderCell: params => {
            if (params.value !== 0 && !params.value) {
                return "No Score Yesterday";
            }

            return <Typography sx={{ width: '100%', textAlign: 'center' }}>{params.value}%</Typography>;
        },
        width: 130,
        visible: true,
    },
    {
        field: "adScoreAverage",
        headerName: "Ad Score 7d Average",
        width: 140,
        type: 'number',
        visible: true,
    },
    {
        field: "recommendationStatus",
        visible: true,
        headerName: "Recommendation",
        width: 150,
        renderCell: (params) => {
            return (
                <Typography variant="inherit">
                    {params.value}
                </Typography>
            );
        },
    },
    {
        field: "adScoreLast7Days",
        headerName: "7d history",
        renderCell: params => {
            if (!params.value) {
                return "";
            }

            return <ScoreChart
                data={params.value}
                invertX={false}
                width={"100%"}
                height={"100%"}
            />
        },
        width: 200,
        visible: true,
    },
    {
        field: "amountSpend",
        headerName: "Ad Spend",
        type: 'number',
        width: 100,
        visible: true,
    },
    {
        field: "adSetID",
        headerName: "Ad Set ID",
        width: 100,
        visible: false,
    },
    {
        field: "adSetName",
        visible: false,
        headerName: "Ad Set Name",
        width: 110,
    },
    {
        field: "campaignID",
        headerName: "Campaign ID",
        width: 100,
        visible: false,
    },
    {
        field: "campaignName",
        headerName: "Campaign Name",
        width: 100,
        visible: false,
    },
    {
        field: "country",
        headerName: "Country",
        width: 110,
        visible: false,
    },
    {
        field: "impressions",
        headerName: "Impressions",
        type: 'number',
        width: 100,
        visible: true,
    },
    {
        field: "clicks",
        headerName: "Clicks",
        type: 'number',
        width: 80,
        visible: true,
    },
    {
        field: "ctr",
        headerName: "CTR",
        type: 'number',
        width: 80,
        visible: true,
    },
    {
        field: "cpc",
        headerName: "CPC",
        type: 'number',
        width: 80,
        visible: true,
    },
    {
        field: "cpm",
        headerName: "CPM",
        type: 'number',
        width: 80,
        visible: true,
    },
    {
        field: "Conversation_Started",
        headerName: "Conversation Started",
        type: 'number',
        width: 150,
        visible: true,
    },
    {
        field: "adSessionMinutes",
        headerName: "Ad SessionMinutes",
        renderCell: (params) => {
            return params.value ? params.value?.toFixed(2) : 0;
        },
        width: 120,
        visible: false,
    },
    {
        field: "adSessions",
        headerName: "Engaged Sessions",
        type: 'number',
        width: 100,
        visible: false,
    },
    {
        field: "adPageviews",
        headerName: "Page Views",
        type: 'number',
        width: 100,
        visible: true,
    },
    {
        field: "addToCarts",
        headerName: "Add To Carts",
        type: 'number',
        width: 100,
        visible: false,
    },
    {
        field: "checkouts",
        headerName: "Checkouts",
        type: 'number',
        width: 100,
        visible: false,
    },
    {
        field: "transactions",
        headerName: "Transactions",
        type: 'number',
        width: 100,
        visible: false,
    },
    {
        field: "sppv",
        headerName: "CPPV",
        type: 'number',
        width: 100,
        visible: false,
    },
    {
        field: "adEngagementRate",
        headerName: "Ad Engagement Rate",
        width: 100,
        visible: false,
    },
    {
        field: "rank",
        headerName: "Rank",
        type: 'number',
        width: 100,
        visible: false,
    },
    {
        field: "contribution",
        headerName: "Contribution",
        renderCell: (params) => {
            return <Typography sx={{ width: '100%', textAlign: 'center' }}>{params.value}%</Typography>;
        },
        width: 100,
        visible: false,
    },
];
export const adDashColumns: GridColDef<IColDef & { skipped?: boolean; }>[] = adDashColumnsAllAttributes.map(({ visible, ...rest }) => rest);
export const columnsVisibility = adDashColumnsAllAttributes.reduce((acc, column) => {
    acc[column.field] = column.visible;

    return acc;
}, {} as GridColumnVisibilityModel);
