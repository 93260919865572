import { IconButton, Tooltip } from "@mui/material";
import { ThumbUp, ThumbDown } from "@mui/icons-material";

interface IReactionButton {
  onClick?: () => void;
  reaction: "thumbsUp" | "thumbsDown";
  selected: boolean;
}

export const ReactionButton = ({
  onClick,
  reaction,
  selected,
}: IReactionButton) => {
  return (
    <Tooltip title={reaction === "thumbsUp" ? "Like" : "Dislike"}>
      <IconButton
        disableRipple
        onClick={onClick}
        size="small"
        sx={{
          color: selected
            ? reaction === "thumbsUp"
              ? "success.dark"
              : "error.main"
            : "inherit",
          ":hover": {
            transform: "scale(1.2)",
            transition: "transform 0.2s",
            color: reaction === "thumbsUp" ? "success.dark" : "error.main",
          },
        }}
      >
        {reaction === "thumbsUp" ? <ThumbUp /> : <ThumbDown />}
      </IconButton>
    </Tooltip>
  );
};
