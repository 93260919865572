import type {
  AxiosError,
  AxiosRequestConfig,
  Method,
  ResponseType,
} from "axios";
import type { BaseQueryFn } from "@reduxjs/toolkit/query";
import ApiService from "@/services/apis/api-service";

const apiService = new ApiService();
export const axiosBaseQuery =
  ({
    baseUrl,
    timeout,
  }: {
    baseUrl: string;
    timeout?: number;
  }): BaseQueryFn<
    {
      url: string;
      method: Method;
      data?: AxiosRequestConfig["data"];
      params?: AxiosRequestConfig["params"];
      headers?: AxiosRequestConfig["headers"];
      responseType?: ResponseType;
      access_token: string;
    },
    unknown,
    unknown
  > =>
  async ({
    url,
    method,
    data,
    params,
    headers = {},
    responseType,
    access_token,
  }) => {
    const axiosReq: AxiosRequestConfig = {
      url: baseUrl + url,
      method,
      data,
      params,
      headers,
    };

    if (responseType) {
      axiosReq.responseType = responseType;
    }

    try {
      apiService.setAccessToken(access_token);

      if (timeout) {
        apiService.setTimetout(timeout);
      }

      const result = await apiService.axios(axiosReq);

      return {
        data: result.data,
        meta: {
          status: result.status,
          statusText: result.statusText,
        },
      };
    } catch (axiosError) {
      const err = axiosError as AxiosError;
      if (err.response?.status === 401) {
        console.log('window location ', window.location);
        if(!window.location.pathname.includes("/info-agent/")) {
          window.location.href = "/";
        }
        return {}
      } else {
        return {
          error: {
            response: err.response,
            status: err.response?.status || err.status,
            data: err.response?.data || err.message,
          },
        };
      }
    }
  };
