// import WhatsAppIcon from "@/components/atoms/Icons/WhatsAppIcon";
// import useFetchAccountState from "@/hooks/store-management/useFetchAccountState";
import { Send } from "@mui/icons-material";
import { Box, Button, TextField } from "@mui/material";
import { useFormik } from "formik";

interface IChatbotTextInput {
  submitInput: (prompt: string) => void;
  info_agent_webview?: boolean;
  isThreadMessageExist?: boolean;
}

export const ChatbotTextInput = ({
  submitInput,
}: IChatbotTextInput) => {
  // const { accountState } = useFetchAccountState({
  //   fetchOnlyStore: true,
  // });
  const formik = useFormik({
    initialValues: {
      prompt: "",
    },
    onSubmit: async (values, { resetForm }) => {
      const prompt = values.prompt;
      if (!prompt) return;

      submitInput(prompt);
      resetForm();
    },
  });

  const handleSubmit = () => {
    formik.handleSubmit();
  };

  const keyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key == "Enter") {
      handleSubmit();
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: 2,
        }}
      >
        <TextField
          id="chatbot-message-input"
          // label="Ask your data warehouse anything such as 'what was the average order value in June?'"
          label="Ask in any language"
          sx={{
            width: "100%",
          }}
          onKeyDown={keyPress}
          name="prompt"
          value={formik.values.prompt}
          onChange={formik.handleChange}
          //   error={
          //     formik.touched.code && Boolean(formik.errors.code)
          //   }
          //   helperText={formik.touched.code && formik.errors.code}
        />
        <Button
          id={"info-agent-submit-chat"}
          variant="contained"
          color="primary"
          disabled={!formik.values.prompt}
          onClick={handleSubmit}
        >
          <Send />
        </Button>
        {/* {accountState?.name?.toLowerCase() === "demo_store" && (
          <a
            href="https://wa.me/12518422455?text=Hi,%20I%20need%20help%20from%20DEMO_STORE"
            target="_blank"
          >
            <Button
              variant="contained"
              color="primary"
              sx={{
                background:
                  "linear-gradient(45deg, #2f5597 60%, rgb(97, 212, 202) 98.3%)",
              }}
            >
              <Box width={"40px"} height={"40px"}>
                <WhatsAppIcon />
              </Box>
              <Typography fontWeight={"bold"}>Connect to WhatsApp</Typography>
            </Button>
          </a>
        )} */}
      </Box>
    </>
  );
};
