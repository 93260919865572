import Dashboard from "@mui/icons-material/Dashboard";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import StorageIcon from "@mui/icons-material/Storage";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EditNoteIcon from "@mui/icons-material/EditNote";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import PriceChangeIcon from "@mui/icons-material/PriceChange";
import DifferenceIcon from "@mui/icons-material/Difference";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import type { SelectChangeEvent } from "@mui/material/Select";
import TableChartIcon from "@mui/icons-material/TableChart";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Skeleton from "@mui/material/Skeleton";
import Tooltip from "@mui/material/Tooltip";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { selectStoreBySearchParam } from "@/utils/store.util";
import useFetchAccountState from "@/hooks/store-management/useFetchAccountState";
import useLogout from "@/hooks/authentication/useLogout";
import useNotification from "@/hooks/useNotification";
import { MESSAGES } from "@/config/dialogMessages.config";
import NewStoreModal from "../../NewStoreModal";
import LogoSection from "../../MainLogo";
import useFetchStore from "@/hooks/store-management/useFetchStore";
import { StoreDropdown } from "../../StoreDropdown";
import { MenuBottomList } from "../MenuBottomList";
import { IStorePermissions } from "@/models/storeConfiguration";
import { columnsVisibility as adDashColumnsVisibility } from "@/pages/dashboard/default/columns";
import { AdSpendColumns } from "@/components/organisms/AdSpendSections/AdSpendTable/columns";
import { useAppDispatch, useAppSelector } from "@/store";
import { setSelectedAccount } from "@/store/slices/store";
import {
  setSelectedAdSets,
  setSelectedAdsWithScore,
  setSelectedCampaigns,
  setSelectedScoreAdsColumnsVisibility,
  setSelectedScoreSetsColumnsVisibility,
} from "@/store/slices/filters";
import { setSelectedPrompt } from "@/store/slices/prompt";

interface MenuListProps {
  drawerOpen: boolean;
  handleLeftDrawerToggle: () => void;
}

function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
function isRouteActive({ routeName }: { routeName: string }) {
  return window.location.pathname.includes(routeName) ? true : false;
}
function isDashboardActive() {
  return location.pathname.includes("/dashboard") ? true : false;
}

function isAdCockpitActive() {
  return location.pathname.includes("/adCockpit") ? true : false;
}

function MenuList({ drawerOpen, handleLeftDrawerToggle }: MenuListProps) {
  const navigate = useNavigate();
  const selectedAccount = useAppSelector(
    (state) => state.store.selectedAccount
  );
  const aggregation = useAppSelector((store) => store.filters.aggregation);
  const [userEmail, setUserEmail] = useState("");
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const [menuItem, setMenuItem] = useState<[string, string][]>([]);
  const [selectedMenu, setSelectedMenu] = useState("");
  const [isOpenNewStore, setIsOpenNewStore] = useState(false);
  const { isAuthenticated, getIdTokenClaims } = useAuth0();
  const { accountState, isLoading: accountStateLoading } = useFetchAccountState(
    {
      selectedAccount,
      fetchOnlyStore: false,
    }
  );
  const { addNotification, removeNotification } = useNotification();
  const {
    stores,
    isLoading: isLoadingStores,
    isFetched: isStoreFetched,
  } = useFetchStore();
  const { handleLogout } = useLogout();

  useEffect(() => {
    if (getIdTokenClaims) {
      getIdTokenClaims().then((e) => {
        if (e) {
          setUserEmail(e.email || "");
        }
      });
    }
  }, [getIdTokenClaims]);

  useEffect(() => {
    if (!isAuthenticated || !isStoreFetched) return;

    if (stores && stores.length) {
      setMenuItem(
        stores.map((_store) => [
          _store.id.toLowerCase(),
          capitalizeFirstLetter(_store.name.toLowerCase()),
        ])
      );

      const selectedStore = selectStoreBySearchParam({
        preSelectedStoreInRedux: selectedAccount,
        storeList: stores,
        setSearchParams,
        searchParams,
      });

      if (
        selectedStore &&
        selectedStore?.id?.toLowerCase() !== selectedAccount.toLowerCase()
      ) {
        const { columnsVisibility: adSpendColumnsVisibility } = AdSpendColumns({
          aggregation,
        });

        dispatch(setSelectedAccount(selectedStore.id));
        dispatch(setSelectedPrompt(null));
        dispatch(setSelectedCampaigns([]));
        dispatch(setSelectedAdSets([]));
        dispatch(setSelectedAdsWithScore(false));
        dispatch(
          setSelectedScoreSetsColumnsVisibility(adSpendColumnsVisibility)
        );
        dispatch(setSelectedScoreAdsColumnsVisibility(adDashColumnsVisibility));

        setSelectedMenu(selectedStore.id.toLowerCase());
      }
    } else {
      setMenuItem([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stores, isStoreFetched, isAuthenticated, location]);

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const currentIndex = document.location.pathname.toString().split("/");
    let val = event.target.value as string;

    if (val !== "newStore") {
      val = val.toLowerCase();
      setSelectedMenu(val);

      if (currentIndex && currentIndex[1] && currentIndex[1] === "dashboard") {
        navigate("/dashboard/" + val + `/?account=${val}`);
      } else if (
        currentIndex &&
        currentIndex[1] &&
        currentIndex[1] === "adCockpit"
      ) {
        navigate("/adCockpit/" + val + `/?account=${val}`);
      } else if (
        currentIndex &&
        currentIndex[1] &&
        currentIndex[1] === "databot"
      ) {
        navigate(`/databot/?account=${val}`);
      } else {
        navigate(location.pathname + `?account=${val}`);
      }
    } else if (val === "newStore") {
      setIsOpenNewStore(true);
    }
  };

  function handleNewStore(account?: string) {
    setIsOpenNewStore(!isOpenNewStore);

    if (account) {
      setSelectedMenu(account.toLowerCase());
      dispatch(setSelectedAccount(account));

      if (isOpenNewStore) {
        addNotification({
          title: MESSAGES.NEW_STORE.ADDED_SUCCESSFULLY.TITLE,
          message: MESSAGES.NEW_STORE.ADDED_SUCCESSFULLY.MESSAGE,
          type: "confirm",
          confirmButtonText:
            MESSAGES.NEW_STORE.ADDED_SUCCESSFULLY.CONFIRM_BUTTON_TEXT,
          onConfirm: async () => {
            navigate(
              `/settings?account=${account ? account.toLowerCase() : null}`
            );
            removeNotification();
          },
        });
      }
    }
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          height: "100%",
          paddingBottom: 3,
          paddingTop: 3,
        }}
      >
        <Box>
          <Box
            style={{
              paddingBottom: "28px",
              display: drawerOpen ? "block" : "none",
            }}
          >
            <Box>
              <LogoSection />
            </Box>
          </Box>
          <Box
            sx={{ mb: 2 }}
            style={{
              display: drawerOpen ? "block" : "none",
            }}
          >
            <StoreDropdown
              isLoading={isLoadingStores}
              storeNames={menuItem}
              selectedStore={selectedMenu}
              onChange={handleChange}
            />
          </Box>
          <List
            sx={{
              paddingTop: drawerOpen ? "0px" : "10px",
            }}
          >
            {!accountState?.permissions && accountStateLoading && (
              <>
                {Array(4)
                  .fill("")
                  .map((_, index) => (
                    <ListItem
                      key={index}
                      disablePadding
                      sx={{ display: "block", mb: 0.5 }}
                    >
                      <ListItemButton
                        sx={{
                          minHeight: 44,
                          justifyContent: drawerOpen ? "initial" : "center",
                          px: 0,
                          py: 0,
                          borderRadius: 1.5,
                        }}
                      >
                        <Skeleton
                          variant="rounded"
                          width="100%"
                          height="44px"
                        />
                      </ListItemButton>
                    </ListItem>
                  ))}
              </>
            )}

            {accountState?.permissions?.canAccessGenAi && (
              <ListItem disablePadding sx={{ display: "block", mb: 0.5 }}>
                <ListItemButton
                  selected={isRouteActive({
                    routeName: "databot",
                  })}
                  onClick={() => {
                    navigate(
                      `/databot/?account=${
                        selectedAccount ? selectedAccount.toLowerCase() : null
                      }`
                    );
                  }}
                  sx={{
                    minHeight: 44,
                    justifyContent: drawerOpen ? "initial" : "center",
                    px: 2,
                    py: 0.5,
                    borderRadius: 1.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: drawerOpen ? 1.8 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <Tooltip title="AI - Ask me anything" placement="right">
                      <img
                        src="/chatbot.png"
                        style={{
                          width: "22px",
                          height: "22px",
                        }}
                      />
                    </Tooltip>
                  </ListItemIcon>
                  <ListItemText
                    id="ad-stats"
                    color="primary"
                    primary={"AI-Agent"}
                    sx={{ opacity: drawerOpen ? 1 : 0 }}
                    primaryTypographyProps={{
                      fontSize: "14px",
                      color: "primary",
                    }}
                  />
                </ListItemButton>
              </ListItem>
            )}
            {accountState?.permissions?.canAccessMasterEdit && (
              <ListItem disablePadding sx={{ display: "block", mb: 0.5 }}>
                <ListItemButton
                  selected={isRouteActive({
                    routeName: "master-edit",
                  })}
                  onClick={() => {
                    navigate(
                      `/master-edit/?account=${
                        selectedAccount ? selectedAccount.toLowerCase() : null
                      }`
                    );
                  }}
                  sx={{
                    minHeight: 44,
                    justifyContent: drawerOpen ? "initial" : "center",
                    px: 2,
                    py: 0.5,
                    borderRadius: 1.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: drawerOpen ? 1.8 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <Tooltip title="Master Edit" placement="right">
                      <TableChartIcon />
                    </Tooltip>
                  </ListItemIcon>
                  <ListItemText
                    id=""
                    color="primary"
                    primary={"Master Edit"}
                    sx={{ opacity: drawerOpen ? 1 : 0 }}
                    primaryTypographyProps={{
                      fontSize: "14px",
                      color: "primary",
                    }}
                  />
                </ListItemButton>
              </ListItem>
            )}
            {accountState?.permissions?.canAccessSqlEdit && (
              <ListItem disablePadding sx={{ display: "block", mb: 0.5 }}>
                <ListItemButton
                  selected={isRouteActive({
                    routeName: "sql-edit",
                  })}
                  onClick={() => {
                    navigate(
                      `/sql-edit/?account=${
                        selectedAccount ? selectedAccount.toLowerCase() : null
                      }`
                    );
                  }}
                  sx={{
                    minHeight: 44,
                    justifyContent: drawerOpen ? "initial" : "center",
                    px: 2,
                    py: 0.5,
                    borderRadius: 1.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: drawerOpen ? 1.8 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <Tooltip title="SQL Edit" placement="right">
                      <StorageIcon />
                    </Tooltip>
                  </ListItemIcon>
                  <ListItemText
                    id=""
                    color="primary"
                    primary={"SQL Edit"}
                    sx={{ opacity: drawerOpen ? 1 : 0 }}
                    primaryTypographyProps={{
                      fontSize: "14px",
                      color: "primary",
                    }}
                  />
                </ListItemButton>
              </ListItem>
            )}
            {accountState?.permissions?.canAccessTextEdit && (
              <ListItem disablePadding sx={{ display: "block", mb: 0.5 }}>
                <ListItemButton
                  selected={isRouteActive({
                    routeName: "text-edit",
                  })}
                  onClick={() => {
                    navigate(
                      `/text-edit/?account=${
                        selectedAccount ? selectedAccount.toLowerCase() : null
                      }`
                    );
                  }}
                  sx={{
                    minHeight: 44,
                    justifyContent: drawerOpen ? "initial" : "center",
                    px: 2,
                    py: 0.5,
                    borderRadius: 1.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: drawerOpen ? 1.8 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <Tooltip title="Text Edit" placement="right">
                      <EditNoteIcon />
                    </Tooltip>
                  </ListItemIcon>
                  <ListItemText
                    id=""
                    color="primary"
                    primary={"Text Edit"}
                    sx={{ opacity: drawerOpen ? 1 : 0 }}
                    primaryTypographyProps={{
                      fontSize: "14px",
                      color: "primary",
                    }}
                  />
                </ListItemButton>
              </ListItem>
            )}
            {accountState?.permissions?.canAccessDataEdit && (
              <ListItem disablePadding sx={{ display: "block", mb: 0.5 }}>
                <ListItemButton
                  selected={isRouteActive({
                    routeName: "data-edit",
                  })}
                  onClick={() => {
                    navigate(
                      `/data-edit/?account=${
                        selectedAccount ? selectedAccount.toLowerCase() : null
                      }`
                    );
                  }}
                  sx={{
                    minHeight: 44,
                    justifyContent: drawerOpen ? "initial" : "center",
                    px: 2,
                    py: 0.5,
                    borderRadius: 1.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: drawerOpen ? 1.8 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <Tooltip title="Data Edit" placement="right">
                      <SaveAsIcon />
                    </Tooltip>
                  </ListItemIcon>
                  <ListItemText
                    id=""
                    color="primary"
                    primary={"Data Edit"}
                    sx={{ opacity: drawerOpen ? 1 : 0 }}
                    primaryTypographyProps={{
                      fontSize: "14px",
                      color: "primary",
                    }}
                  />
                </ListItemButton>
              </ListItem>
            )}
            {(accountState?.permissions?.canAccessViewWAConversation ||
              accountState?.permissions?.canAccessMasterEdit) && (
              <ListItem disablePadding sx={{ display: "block", mb: 0.5 }}>
                <ListItemButton
                  selected={isRouteActive({
                    routeName: "view-conversation",
                  })}
                  onClick={() => {
                    navigate(
                      `/view-conversation/?account=${
                        selectedAccount ? selectedAccount.toLowerCase() : null
                      }`
                    );
                  }}
                  sx={{
                    minHeight: 44,
                    justifyContent: drawerOpen ? "initial" : "center",
                    px: 2,
                    py: 0.5,
                    borderRadius: 1.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: drawerOpen ? 1.8 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <Tooltip
                      title="View WhatsApp Conversation"
                      placement="right"
                    >
                      <WhatsAppIcon />
                    </Tooltip>
                  </ListItemIcon>
                  <ListItemText
                    id=""
                    color="primary"
                    primary={"View WA Conversation"}
                    sx={{ opacity: drawerOpen ? 1 : 0 }}
                    primaryTypographyProps={{
                      fontSize: "14px",
                      color: "primary",
                    }}
                  />
                </ListItemButton>
              </ListItem>
            )}
            {accountState?.permissions?.canAccessScoreAds && (
              <ListItem disablePadding sx={{ display: "block", mb: 0.5 }}>
                <ListItemButton
                  data-cy="menu-item-ad-dash"
                  selected={isDashboardActive()}
                  onClick={() => {
                    navigate(
                      `/dashboard/${
                        selectedAccount ? selectedAccount.toLowerCase() : null
                      }`
                    );
                  }}
                  sx={{
                    minHeight: 44,
                    justifyContent: drawerOpen ? "initial" : "center",
                    px: 2,
                    py: 0.5,
                    borderRadius: 1.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: drawerOpen ? 1.8 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <Tooltip title="Score Ads" placement="right">
                      <Dashboard />
                    </Tooltip>
                  </ListItemIcon>
                  <ListItemText
                    id="ad-dash"
                    primary={"Score Ads"}
                    sx={{ opacity: drawerOpen ? 1 : 0 }}
                    primaryTypographyProps={{ fontSize: "14px" }}
                  />
                </ListItemButton>
              </ListItem>
            )}
            {accountState?.permissions?.canAccessAdCockpit && (
              <ListItem disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  selected={isAdCockpitActive()}
                  onClick={() => {
                    navigate(
                      `/adCockpit/${
                        selectedAccount ? selectedAccount.toLowerCase() : null
                      }`
                    );
                  }}
                  sx={{
                    minHeight: 44,
                    justifyContent: drawerOpen ? "initial" : "center",
                    px: 2,
                    py: 0.5,
                    borderRadius: 1.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: drawerOpen ? 1.8 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <Tooltip title="AdCockpit" placement="right">
                      <ViewColumnIcon />
                    </Tooltip>
                  </ListItemIcon>
                  <ListItemText
                    id="ad-cockpit"
                    primary={"AdCockpit"}
                    sx={{ opacity: drawerOpen ? 1 : 0 }}
                    primaryTypographyProps={{ fontSize: "14px" }}
                  />
                </ListItemButton>
              </ListItem>
            )}
            {accountState?.permissions?.canAccessScoreAdSets && (
              <ListItem disablePadding sx={{ display: "block", mt: 0.5 }}>
                <ListItemButton
                  selected={isRouteActive({
                    routeName: "ad-spend",
                  })}
                  onClick={() => {
                    navigate(`/ad-spend`);
                  }}
                  sx={{
                    minHeight: 44,
                    justifyContent: drawerOpen ? "initial" : "center",
                    px: 2,
                    py: 0.5,
                    borderRadius: 1.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: drawerOpen ? 1.8 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <Tooltip title="Score AdSets" placement="right">
                      <PriceChangeIcon />
                    </Tooltip>
                  </ListItemIcon>
                  <ListItemText
                    id="ad-spend"
                    primary={"Score AdSets"}
                    sx={{ opacity: drawerOpen ? 1 : 0 }}
                    primaryTypographyProps={{ fontSize: "14px" }}
                  />
                </ListItemButton>
              </ListItem>
            )}
            {accountState?.permissions?.canAccessStoreStats && (
              <ListItem disablePadding sx={{ display: "block", mt: 0.5 }}>
                <ListItemButton
                  selected={isRouteActive({
                    routeName: "store-stats",
                  })}
                  onClick={() => {
                    navigate(`/store-stats`);
                  }}
                  sx={{
                    minHeight: 44,
                    justifyContent: drawerOpen ? "initial" : "center",
                    px: 2,
                    py: 0.5,
                    borderRadius: 1.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: drawerOpen ? 1.8 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <Tooltip title="Store Stats" placement="right">
                      <AutoGraphIcon />
                    </Tooltip>
                  </ListItemIcon>
                  <ListItemText
                    id="store-stats"
                    primary={"Store Stats"}
                    sx={{ opacity: drawerOpen ? 1 : 0 }}
                    primaryTypographyProps={{ fontSize: "14px" }}
                  />
                </ListItemButton>
              </ListItem>
            )}
            {accountState?.permissions?.canAccessAdStoreCard && (
              <ListItem disablePadding sx={{ display: "block", mt: 0.5 }}>
                <ListItemButton
                  selected={isRouteActive({
                    routeName: "ad-store-card",
                  })}
                  onClick={() => {
                    navigate(`/ad-store-card`);
                  }}
                  sx={{
                    minHeight: 44,
                    justifyContent: drawerOpen ? "initial" : "center",
                    px: 2,
                    py: 0.5,
                    borderRadius: 1.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: drawerOpen ? 1.8 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <Tooltip title="AdStoreCard" placement="right">
                      <DifferenceIcon />
                    </Tooltip>
                  </ListItemIcon>
                  <ListItemText
                    id="ad-store-card"
                    primary={"AdStoreCard"}
                    sx={{ opacity: drawerOpen ? 1 : 0 }}
                    primaryTypographyProps={{ fontSize: "14px" }}
                  />
                </ListItemButton>
              </ListItem>
            )}
          </List>
        </Box>
        <Box>
          <MenuBottomList
            drawerOpen={drawerOpen}
            selectedAccount={selectedAccount}
            userEmail={userEmail}
            handleLeftDrawerToggle={handleLeftDrawerToggle}
            handleLogout={handleLogout}
            permissions={accountState?.permissions || ({} as IStorePermissions)}
          />
        </Box>
      </Box>
      <NewStoreModal open={isOpenNewStore} handleNewStore={handleNewStore} />
    </>
  );
}

export default MenuList;
