import useNotification from "@/hooks/useNotification";
import { useChatSessionsQuery } from "@/store/api/chatbot";
import { useEffect } from "react";
import { MESSAGES } from "@/config/dialogMessages.config";
import { ISession } from "@/models/session";

/**
 * Chatbot utility hook
 */
export default function useChatbotSessions({ store }: { store: string }) {
  const { addNotification } = useNotification();
  const { data, isLoading, isError, refetch } = useChatSessionsQuery({ store }, {skip: !store});

  useEffect(() => {
    if (!isLoading && isError) {
      addNotification({
        title: "Oops!",
        message: MESSAGES.GENERAL_ERROR_MESSAGE,
        type: "error",
      });
    }
  }, [addNotification, isError, isLoading]);

  // Function to sort the sessions by UpdatedAt
  const sortByUpdatedAt = (sessions: ISession[]): ISession[] => {
    return sessions.sort(
      (a, b) =>
        new Date(b.UpdatedAt).getTime() - new Date(a.UpdatedAt).getTime()
    );
  };

  return {
    data:
      data?.data && data?.data?.[0]
        ? sortByUpdatedAt([...(data?.data || [])])
        : data?.data,
    isLoading,
    refetch,
  };
}
