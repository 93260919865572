import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  sessionId: null as string | null,
};

const chatbotSlices = createSlice({
  name: "chatbot",
  initialState,
  reducers: {
    setSessionId: (state, action: PayloadAction<string>) => {
      state.sessionId = action.payload;
    },
  },
});

export const { setSessionId } = chatbotSlices.actions;

export default chatbotSlices.reducer;
