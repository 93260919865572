import { Box, Button } from "@mui/material";

export interface IChatbotCustomTemplate {}

const PRE_DEFINE_PROMPTS = [
  "Hi agent! Please introduce yourself and tell me what this is about.",
  "Tell me more about the company and why this is important to me!",
  "Explain how an AI agent helps my inbound marketing and sales!",
];

const PromptButton = ({
  text,
  onClick,
}: {
  text: string;
  onClick: () => void;
}) => {
  return (
    <Button
      onClick={onClick}
      sx={{
        width: "256px",
        
        height: "128px",
        bgcolor: "primary.light",
        p: 2,
        borderRadius: 6,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        textTransform: "none",
        background:
          "radial-gradient(592px at 48.2% 50%, rgba(255, 255, 249, 0.6) 0%, rgb(160, 199, 254) 74.6%);",
        transition: "transform 0.3s ease", // Transition for smooth animation
        "&:hover": {
          transform: "scale(1.05)", // Scale the button on hover
        },
      }}
    >
      {text}
    </Button>
  );
};

const ChatbotCustomTemplate = ({
  onCustomPromptSubmit,
}: {
  onCustomPromptSubmit: (prompt: string) => void;
}) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      // alignItems="center"
      flexDirection="column"
      sx={{
        marginTop: { xs: 0, sm: 0, md: 2, lg: 15 },
        marginBottom: { xs: 0, sm: 0, md: 2, lg: 5 },
        maxWidth: 700,
        marginX: "auto",
        "@media (max-width: 350px)": {
          marginTop: 0,
        },
      }}
        >
      {/* <Typography variant="h2" color="primary.main" textAlign={"center"}>
        <span className="orange-text">AI</span>-Agent
      </Typography>
      <Typography variant="h5" color={"primary"} textAlign="justify" marginTop={4}>
        You can ask me question about the connected data, general questions
        about the data model, but also generic questions that each GenAI such as
        ChatGPT can answer.
      </Typography>
      <Typography variant="body1" textAlign="left" marginTop={6} color={"primary"}>
        Here are some examples typical for e-commerce:
      </Typography> */}
      {/* <Typography variant="h3" color="primary.main">
        Ask <span className="orange-text">Adscore AI</span> Anything
      </Typography> */}
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        gap={2}
        mt={2}
        flexWrap="wrap"
      >
        {PRE_DEFINE_PROMPTS.map((prompt) => (
          <PromptButton
            key={prompt}
            text={prompt}
            onClick={() => onCustomPromptSubmit(prompt)}
          />
        ))}
      </Box>
      {/* , French, German or Spanish */}
      {/* <Box marginTop={4} textAlign={'center'} maxWidth={400} marginX={"auto"} padding={2} borderRadius={4} >
        <Typography variant="caption" color={"primary"}>
          You can ask question in English, French, German or Spanish. More
          languages are added soon.
        </Typography>
      </Box> */}
    </Box>
  );
};

export default ChatbotCustomTemplate;
