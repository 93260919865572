import { Box } from "@mui/material";
import "./style.css";

export default function BouncingLoader() {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          "& > div": {
            width: "10px",
            height: "10px",
            margin: "0 4px",
            backgroundColor: "grey.500",
            borderRadius: "50%",
            animation: "bounce 1.4s infinite both",
          },
          "& > div:nth-of-type(1)": {
            animationDelay: "0s",
          },
          "& > div:nth-of-type(2)": {
            animationDelay: "0.2s",
          },
          "& > div:nth-of-type(3)": {
            animationDelay: "0.4s",
          },
        }}
      >
        <div></div>
        <div></div>
        <div></div>
      </Box>
      <style>
        {`
                      @keyframes bounce {
                        0%, 80%, 100% {
                          transform: scale(0);
                        }
                        40% {
                          transform: scale(1);
                        }
                      }
                    `}
      </style>
    </>
  );
}
